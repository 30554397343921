<template>
    <div class="site-struct extended">

        <LoadingStruct is-dark :is-loading="loading">
            <Card is-dark mode="default">

                <InfoBox
                    v-if="!settings.licenses[0] || !settings.licenses[0].licensedUntil || isDateStringPast(settings.licenses[0].licensedUntil)"
                    mode="danger"
                    icon="warning"
                    class="uk-margin-medium-bottom">

                    <div>
                        <Translate translation-key="no.active.license"/>
                    </div>


                    <Translate v-if="settings.licenses[0] && settings.licenses[0].licensedUntil"
                               translation-key="downloader.disabled.message"
                               :parameter="{
                                 duration: getDaysLeftByDateString(settings.licenses[0].licensedUntil, false),
                                 datetime: getFormattedDateTimeByDateString(settings.licenses[0].licensedUntil),
                                 description: $t('system')+'-',
                               }"
                    />


                </InfoBox>


                <ul uk-tab="animation: uk-animation-fade" id="tabs">
                    <li v-for="(tab, key) in tabs" :key="tab"
                        :class="getTabStatus(key, tab)"
                        v-on:click="setHashtag(tab)">
                        <a href="#">{{ $t(tab) }}</a>
                    </li>
                    <li class="uk-width-expand">
                    </li>


                    <li class="uk-text-right" :uk-tooltip="'title:'+$t('support')">
                        <a :href="supportLink" target="_blank" v-on:click="openSupportLink()">
                            <Icon icon="lifesaver"/>
                        </a>
                    </li>
                    <li class="uk-text-right" :uk-tooltip="'title:'+$t('logout')">
                        <a v-on:click="logout()">
                            <Icon icon="sign-out"/>
                        </a>
                    </li>

                </ul>

                <ul class="uk-switcher uk-margin">



                    <li>
                        <SmartSignageFilesForm
                            :disabled="!settings.activeModules || !settings.activeModules.includes('smartSignage')"
                            :settings="settings"
                            @updated="receiveSettings"/>
                    </li>


                    <li>
                        <div
                            class="module-disabled-info"
                            :uk-tooltip="
            !settings.activeModules || !settings.activeModules.includes('smartSignage')
                ? 'title:' + $t('x.module.disabled.hint', {x: $t('smartSignage')})
                : ''
        "
                            :class="!settings.activeModules || !settings.activeModules.includes('smartSignage') ? 'module-disabled-struct' : ''">
        <span
            :class="
                !settings.activeModules || !settings.activeModules.includes('smartSignage') ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'
            ">

                        <Card is-dark mode="default">

                            <div uk-grid>
                                <div class="uk-width-1-1 uk-text-center cloudConnector-title">
                                    CloudConnector
                                </div>
                            </div>

                            <div class="uk-child-width-auto uk-grid-match uk-flex-center" uk-grid>


                                <div class="satellite-struct uk-text-center ">
                                    <EllipseStatus
                                        :color="getEllipseColorByStatus()"
                                        :empty-color="getEllipseColorByStatus()" :no-data="!lastOnline"
                                        :progress="progress"
                                        class="uk-margin-bottom"
                                    >
                                        <template v-slot:caption>
                                            <div v-if="settings.connector">

                                                <div v-if="lastOnline">
                                                    <div class="lastactivity">{{ $t('lastActivity') }}</div>
                                                    <div :uk-tooltip="'title:'+lastOnline" class="status-text active">{{
                                                            lastOnlineSeconds
                                                        }}
                                                    </div>
                                                </div>
                                                <div v-else class="status-text">
                                                    {{ $t('no.activity.yet') }}
                                                </div>
                                            </div>
                                            <div v-else class="status-text">
                                                {{ $t('notInstalled') }}
                                            </div>
                                        </template>
                                    </EllipseStatus>


                                </div>

                                <div class="uk-width-1-1">
                                    <InfoBox v-if="settings.connector"
                                             mode="primary">
                                        {{ $t('cloudConnector.setup.hint') }}
                                    </InfoBox>

                                    <InfoBox v-else>
                                        {{ $t('cloudConnector.setup.description') }}

                                        <div class="uk-margin-top"/>
                                        <div class="uk-grid-small uk-child-width-1-1" uk-grid>
                                            <div>
                                                <span
                                                    class="uk-margin-small-right">1.</span>{{
                                                    $t('cloudConnector.step1.message')
                                                }}
                                            </div>
                                            <div><span
                                                class="uk-margin-small-right">2.</span>{{
                                                    $t('cloudConnector.step2.message')
                                                }}
                                            </div>
                                            <div><span
                                                class="uk-margin-small-right">3.</span>{{
                                                    $t('cloudConnector.step3.message')
                                                }}
                                            </div>
                                        </div>
                                    </InfoBox>
                                </div>

                                <div class="uk-width-1-1">
                                    <span v-if="settings.connector || codeRegened">
                                        <ToggleItem :is-disabled="code !== null"
                                                    toggle="target: #regen-code">
                                            <Button :text="$t('regen.setup.code')"
                                                    :is-disabled="code !== null"
                                                    is-large is-max mode="danger"/>
                                        </ToggleItem>

                                        <Modal id="regen-code" :title="$t('regen.setup.code')">
                                            <div class="uk-margin-medium-bottom uk-text-center"
                                                 v-html="$t('regen.setup.code.warning')"/>

                                            <div v-if="!successReset" class="uk-flex uk-flex-center" uk-grid>
                                                <div>
                                                    <Button :text="$t('cancel')" class="uk-modal-close" is-large
                                                            is-max is-min-width
                                                            mode="default"/>
                                                </div>
                                                <div>
                                                    <Button :action="regenCode" :is-loading="loadingCode"
                                                            :text="$t('regen')"
                                                            has-action
                                                            is-large is-max
                                                            is-min-width mode="danger"/>
                                                </div>
                                            </div>
                                        </Modal>
                                    </span>

                                    <Button
                                        v-else
                                        :text="$t('gen.setup.code')"
                                        is-large
                                        :action="genCode" has-action
                                        :is-loading="loadingCode"
                                        is-max is-min-width
                                        :is-disabled="code !== null"
                                        mode="success"/>

                                    <div v-if="errorCode" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorCode"
                                                  :custom-errors="[ {code: 403, message: $t('x.module.disabled.hint', {x: $t('smartSignage')})}]"/>


                                    <div v-if="successCode" class="uk-margin-medium-bottom"/>
                                    <div v-if="successCode" class="uk-animation-fade" id="code">
                                        <TextField icon="code"
                                                   classes="cursor-pointer"
                                                   name="setup-code"
                                                   label="setup.code"
                                                   :tooltip="$t('copy.to.clipboard')"
                                                   v-model="codeValue"
                                                   :on-click="codeToClipboard"
                                                   :on-change="resetCode"
                                                   :action-function="codeToClipboard"
                                                   action-icon="copy-clipboard"
                                                   :action-tooltip="$t('copy.to.clipboard')"/>
                                    </div>

                                    <div v-if="successCode" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage
                                        :message="$t('gen.setup.code.success')"
                                        :success="successCode"/>

                                </div>
                            </div>


                            <hr class="uk-margin-medium-top uk-margin-medium-bottom"/>

                            <div class="uk-flex-center" uk-grid>
                            <div v-for="download in settings.connectorInfos" :key="download.name"
                                 class="uk-text-center">
                                <a :href="download.link">
                                    <RoundButton icon="cloud-download"/>
                                </a>
                                <div class="uk-margin-small-top system-title uk-link-reset">
                                    <a :href="download.link">
                                        {{ download.name }}
                                    </a>
                                </div>
                            </div>
                            </div>

                        </Card>
               </span>
                        </div>
                    </li>


                    <li>
                        <div class="uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match" uk-grid>

                            <div class="uk-width-1-1">


                                <License v-if="settings.licenses[0] && settings.licenses[0].licensedUntil"
                                         :license-link="settings.licenseLink"
                                         :licensed-until="settings.licenses[0].licensedUntil"
                                         :description="$t('smartSignage')"
                                />
                                <div v-else>
                                    <InfoBox mode="primary" icon="info">
                                        <Translate translation-key="no.active.license"/>
                                    </InfoBox>
                                </div>
                            </div>

                            <div>
                                <Card is-dark mode="default">
                                    <span v-for="(inputField, key) in emailFields" :key="inputField.name">
                                        <TextField v-model="inputField.value"
                                                   :autocomplete="inputField.autocomplete"
                                                   :autofocus="inputField.autofocus"
                                                   :field-key="key"
                                                   :hasPasswordMeter="inputField.hasPasswordMeter"
                                                   :icon="inputField.icon"
                                                   :is-optional="inputField.optional"
                                                   :label="inputField.label"
                                                   :name="inputField.name"
                                                   :on-enter="saveEmail"
                                                   :options="inputField.options"
                                                   :rules="inputField.rules"
                                                   :steps="inputField.steps"
                                                   :type="inputField.type"
                                                   :validate="triggerEmail"
                                                   is-label-large
                                                   @setValid="setEmailValid"
                                        />
                                        <div v-if="key < emailFields.length-1" class="uk-margin-bottom"/>
                                    </span>

                                    <div class="uk-margin-medium-bottom"/>
                                    <Button :action="saveEmail" :is-loading="loadingEmail"
                                            :text="$t('x.save', {x: $t('emailAddress')})"
                                            has-action is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorEmail" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorEmail"/>

                                    <div v-if="successEmail" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('emailAddress')})"
                                                    :success="successEmail"/>
                                </Card>
                            </div>
                            <div>
                                <Card :title="$t('password')" is-dark mode="default">
                                    <span v-for="(inputField, key) in passwordFields" :key="inputField.name">
                                        <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                                                   :autofocus="inputField.autofocus" :field-key="key"
                                                   :hasPasswordMeter="inputField.hasPasswordMeter"
                                                   :icon="inputField.icon"
                                                   :is-optional="inputField.optional"
                                                   :label="inputField.label"
                                                   :name="inputField.name"
                                                   :on-enter="savePassword"
                                                   :rules="inputField.rules"
                                                   :type="inputField.type"
                                                   :validate="triggerPassword"
                                                   @setValid="setPasswordValid"
                                        />
                                        <div v-if="key < passwordFields.length-1" class="uk-margin-bottom"/>
                                    </span>

                                    <div class="uk-margin-medium-bottom"/>
                                    <Button :action="savePassword" :is-loading="loadingPassword"
                                            :text="$t('x.save', {x: $t('password')})" has-action
                                            is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorPassword" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :custom-errors="[{code: 403, message: $t('password.invalid')}]"
                                                  :error="errorPassword"/>

                                    <div v-if="successPassword" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('password')})"
                                                    :success="successPassword"/>
                                </Card>
                            </div>

                            <div v-if="settings.documents && settings.documents.length > 0">
                                <Card :title="$t('documents')" is-dark mode="default">
                                    <div class="uk-margin-medium-bottom"/>

                                    <div class="uk-grid-small uk-child-width-auto" uk-grid>
                                        <div v-for="document in settings.documents" :key="document"
                                             class="uk-text-center">
                                            <span v-on:click="downloadDocument(document)">
                                                <RoundButton :is-loading="documentMeta[document]"
                                                             icon="cloud-download"/>
                                            </span>
                                            <div class="document-name">{{ document }}</div>
                                        </div>
                                    </div>

                                </Card>
                            </div>


                            <div>
                                <Card :title="$t('modules')" is-dark mode="default">
                                    <Description :text="$t('active.modules.message')"/>

                                    <div class="uk-grid-small uk-child-width-auto" uk-grid>
                                        <div v-for="module in settings.activeModules" :key="module">
                                            <Label type="info">
                                                {{ capitalize($t(module)) }}
                                            </Label>
                                        </div>

                                        <div v-if="!settings.activeModules || settings.activeModules.length === 0">
                                            {{ $t('no.modules') }}
                                        </div>
                                    </div>

                                    <span v-if="settings.inactiveModules">
                                        <hr class="uk-margin-medium-top uk-margin-medium-bottom"/>

                                        <Description :text="$t('inactive.modules.message')"/>
                                        <div class="uk-grid-small uk-child-width-auto" uk-grid>
                                            <div v-for="module in settings.inactiveModules" :key="module">
                                                <Label type="default">
                                                    {{ capitalize($t(module)) }}
                                                </Label>
                                            </div>
                                        </div>
                                    </span>


                                </Card>
                            </div>


                            <div>
                                <Card :title="$t('limits')" is-dark mode="default">
                                    <Description :text="$t('limits.message')"/>

                                    <div class="uk-grid-small uk-child-width-1-1" uk-grid>

                                        <div uk-grid>
                                            <div class="uk-width-expand">
                                                <Translate translation-key="limit.slideshows"/>
                                            </div>
                                            <div class="uk-width-auto">
                                                <Label type="info">{{ settings.maxSlideShowFiles }}</Label>
                                            </div>
                                        </div>



                                    </div>




                                </Card>
                            </div>


                            <div>
                                <Card :title="$t('version')" is-dark mode="default">
                                    <Description :text="$t('version.message')"/>
                                    <Label type="info">{{ settings.version }}</Label>
                                </Card>
                            </div>


                            <div>
                                <Card :title="$t('logout')" is-dark mode="default">
                                    <Description :text="$t('logout.message')"/>

                                    <Button :action="logout" :text="$t('logout')" has-action is-large is-max
                                            mode="default"/>
                                </Card>
                            </div>


                            <div>
                                <Card :title="$t('reset')" is-dark mode="default">
                                    <Description :text="$t('system.reset.message')"/>
                                    <ToggleItem toggle="target: #reset">
                                        <Button :text="$t('system.reset')" is-large is-max mode="danger"/>
                                    </ToggleItem>

                                    <Modal id="reset" :title="$t('system.reset')">
                                        <div v-if="!successReset" class="uk-margin-medium-bottom uk-text-center"
                                             v-html="$t('system.reset.confirm')"/>

                                        <div v-if="!successReset" class="uk-flex uk-flex-center" uk-grid>
                                            <div>
                                                <Button :text="$t('cancel')" class="uk-modal-close" is-large
                                                        is-max is-min-width
                                                        mode="default"/>
                                            </div>
                                            <div>
                                                <Button :action="reset" :is-loading="loadingReset"
                                                        :text="$t('system.reset')"
                                                        has-action
                                                        is-large is-max
                                                        is-min-width mode="danger"/>
                                            </div>
                                        </div>

                                        <div v-if="successReset" class="uk-margin-medium-bottom"/>
                                        <SuccessMessage :message="$t('reset.success')"
                                                        :success="successReset"/>

                                        <div v-if="successReset" class="uk-margin-bottom"/>
                                        <LinkObject v-if="successReset" to="setup">
                                            <Button :text="$t('setup')" class="uk-modal-close" is-large is-max
                                                    mode="primary"/>
                                        </LinkObject>
                                    </Modal>

                                    <div v-if="errorReset" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorReset"/>
                                </Card>
                            </div>

                        </div>
                    </li>


                </ul>
            </Card>

        </LoadingStruct>

    </div>
</template>

<script>

import LoadingStruct from "@/views/LoadingStruct";
import Card from "@/components/generic/Card";
import Icon from "@/components/generic/Icon";
import Button from "@/components/generic/Button";
import UIkit from "uikit";
import TextField from "@/components/generic/TextField";
import Description from "@/components/Description";
import Modal from "@/components/generic/Modal";
import ToggleItem from "@/components/generic/ToggleItem";
import Vue from 'vue';
import axios from "axios";
import ErrorMessage from "@/components/generic/ErrorMessage";
import SuccessMessage from "@/components/generic/SuccessMessage";
import LinkObject from "@/components/LinkObject";
import Label from "@/components/Label";
import RoundButton from "@/components/RoundButton";
import VueCookie from "vue-cookie";
import dayjs from "dayjs";
import InfoBox from "@/components/generic/InfoBox.vue";
import Translate from "@/components/Translate.vue";
import License from "@/components/License.vue";
import EllipseStatus from "@/components/EllipseStatus.vue";
import SmartSignageFilesForm from "@/components/forms/SmartSignageFilesForm.vue";

export default {
    name: 'Admin',
    components: {
        SmartSignageFilesForm,
        EllipseStatus,

        License,
        Translate,


        InfoBox,
        RoundButton,

        Label,
        LinkObject,
        SuccessMessage,
        ErrorMessage,
        ToggleItem,
        Modal,
        Description,
        TextField,
        Button,
        Icon,

        Card,
        LoadingStruct,
    },
    data() {
        return {
            loading: true,
            error: null,

            triggerPassword: 0,
            passwordFields: [
                {
                    value: '',
                    valid: false,

                    name: "password",
                    label: "password.current",
                    icon: "unlock",
                    rules: "required",
                    autocomplete: "password",
                    type: "password",
                },
                {
                    value: '',
                    valid: false,

                    name: "password-new",
                    label: "password.new",
                    icon: "lock",
                    rules: "required|min:8|max:4096",
                    autocomplete: "new-password",
                    type: "password",
                    hasPasswordMeter: true,
                },
            ],

            triggerEmail: 0,
            emailFields: [
                {
                    value: '',
                    valid: true,

                    name: "email",
                    label: "emailAddress",
                    icon: "mail",
                    rules: "required|emailV2",
                },
            ],

            tabs: [
                'files',
                'cloud.connector',
                'system',
            ],

            settings: {
                email: '',
                name: '',
                language: '',
                version: '',
                activeModules: [],
                licenses: [
                    {
                        name: '',
                        licensedUntil: ''
                    }
                ],
                licenseLink: '',
                connectorInfos: [
                    {
                        name: '',
                        link: ''
                    }
                ],
                allowedSlideShowFileMimeTypes: [],
                connector: {},
            },

            errorReset: null,
            loadingReset: false,
            successReset: false,

            errorEmail: null,
            loadingEmail: false,
            successEmail: false,

            code: null,
            codeValue: null,
            codeRegened: false,
            errorCode: null,
            loadingCode: false,
            successCode: false,

            errorPassword: null,
            loadingPassword: false,
            successPassword: false,

            documentMeta: [],

            supportLink: "https://phy-prints.com/version.php",

            iconRatio: 2.0,
            lastOnline: "",
            lastOnlineSeconds: "",
            progress: 100,

            interval: 0,
        }
    },
    created() {
        dayjs.locale(this.$i18n.locale);
        this.receiveSettings();
    },
    mounted() {
        dayjs.locale(this.$i18n.locale);
    },
    methods: {
        setupLastOnline() {
            let lastOnline = this.getLastOnline();
            if (lastOnline) {
                this.progress = this.getProgress(lastOnline);
                this.lastOnline = this.getFormattedDateTimeByDateString(lastOnline);
                this.lastOnlineSeconds = this.getAgoDescription(lastOnline)

                let self = this;
                this.interval = setInterval(function () {
                    self.receiveConnector();

                    let lastOnline = self.getLastOnline();
                    self.progress = self.getProgress(lastOnline);
                    self.lastOnline = self.getFormattedDateByDateString(lastOnline);
                    self.lastOnlineSeconds = self.getAgoDescription(lastOnline)
                }, 1000);
            }
        },
        getLineClassByStatus() {
            if (this.settings.connector) {
                if (this.lastOnline) {
                    return "success";
                }

                return "primary";
            }

            return "default";
        },
        getEllipseColorByStatus() {
            if (this.settings.connector) {
                if (this.lastOnline) {
                    return "#30C791FF";
                }

                return "#0071e3";
            }

            return "#aaa";
        },
        getLastOnline() {
            if (!this.settings.connector) {
                console.log(this.settings.connector)

                return null;
            }

            return this.settings.connector.activityAt;
        },
        getAgoDescription(date) {
            let secondsAgo = this.getSecondsAgoByDateString(date);
            if (secondsAgo < 60) {
                return secondsAgo + " " + this.$t('seconds');
            }

            return dayjs(date).fromNow(false);
        },
        getProgress(date) {
            let secondsAgo = this.getSecondsAgoByDateString(date);
            let progress = 100 - this.getPercentage(30, secondsAgo);

            if (progress < 0) {
                return 0;
            }

            return progress;
        },

        receiveSettings() {
            let http = axios.create();
            http.get(this.apiUrl + '/settings').then(response => {
                let settings = response.data;


                this.emailFields[0].value = settings.email;


                this.settings = settings;

                if (!this.interval) {
                    this.setupLastOnline();
                }

                this.error = null;
                this.loading = false;

            }).catch(e => {
                this.checkGenericError(e);
                this.error = e;
                this.loading = false;

            });
        },
        receiveConnector() {
            if (!this.settings.connector) {
                return;
            }

            if (!this.settings.connector.activityAt) {
                return;
            }

            let http = axios.create();
            http.get(this.apiUrl + '/connector').then(response => {
                this.settings.connector.activityAt = response.data.activityAt;

            }).catch(e => {
                this.checkGenericError(e);

            });
        },

        openSupportLink() {
            event.stopPropagation();
            event.preventDefault();
            window.open(this.supportLink, '_blank').focus();
        },


        downloadDocument(doc) {
            event.preventDefault();
            let self = this;

            Vue.set(self.documentMeta, doc, true);

            let request = new XMLHttpRequest();
            request.open("GET", this.apiUrl + '/docs/' + doc, true);
            request.responseType = "blob";
            request.onload = function () {
                let blob = request.response;
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = doc;
                link.click();

                Vue.set(self.documentMeta, doc, false);
            };

            request.setRequestHeader("token", VueCookie.get("token"));
            request.send();
        },
        setPasswordValid(valid, fieldKey) {
            this.successPassword = false;
            this.passwordFields[fieldKey].valid = valid
        },

        setEmailValid(valid, fieldKey) {
            this.successEmail = false;
            this.emailFields[fieldKey].valid = valid
        },


        notify(message) {
            this.receiveInfos();

            UIkit.notification({
                message: message,
                status: 'info',
                pos: 'top-center',
                timeout: this.notificationTimeout,
            });
        },
        notifyChanged(x) {
            this.notify(this.$t('x.changes.saved', {x: x}));
        },
        savePassword() {
            this.triggerPassword++;

            for (let i = 0, j = this.passwordFields.length; i < j; i++) {
                if (!this.passwordFields[i].valid) {
                    return;
                }
            }

            this.loadingPassword = true;

            let http = axios.create();
            http.patch(this.apiUrl + '/password', {
                currentPassword: this.passwordFields[0].value,
                newPassword: this.passwordFields[1].value
            }).then(() => {
                this.errorPassword = null;
                this.loadingPassword = false;
                this.successPassword = true;
                this.receiveInfos();

            }).catch(e => {
                this.checkGenericError(e);
                this.errorPassword = e;
                this.loadingPassword = false;
                this.successPassword = false;

            });
        },
        saveEmail() {
            this.triggerEmail++;

            for (let i = 0, j = this.emailFields.length; i < j; i++) {
                if (!this.emailFields[i].valid) {
                    return;
                }
            }

            this.loadingEmail = true;

            let http = axios.create();
            http.patch(this.apiUrl + '/email', {email: this.emailFields[0].value}).then(() => {
                this.errorEmail = null;
                this.loadingEmail = false;
                this.successEmail = true;
                this.receiveInfos();

            }).catch(e => {
                this.checkGenericError(e);
                this.errorEmail = e;
                this.loadingEmail = false;

            });
        },


        codeToClipboard() {
            this.copyToClipboard(this.code);
        },
        resetCode() {
            this.codeValue = this.code;
        },
        regenCode() {
            this.codeRegened = true;
            this.genCode(true);
        },
        genCode(closeModal = false) {
            this.loadingCode = true;
            let http = axios.create();
            http.post(this.apiUrl + '/connector').then((response) => {
                this.code = response.data.token;
                this.codeValue = response.data.token;

                this.errorCode = null;
                this.loadingCode = false;
                this.successCode = true;
                this.receiveSettings();

                if (closeModal) {
                    UIkit.modal("#regen-code").hide();
                }

                setTimeout(function () {
                    let elementId = "code";
                    UIkit.scroll("#" + elementId, {offset: 0}).scrollTo("#" + elementId);
                }, 10);

            }).catch(e => {
                this.checkGenericError(e);
                this.errorCode = e;
                this.loadingCode = false;
                this.successCode = false;

                if (closeModal) {
                    UIkit.modal("#regen-code").hide();
                }

            });
        },

        reset() {
            this.loadingReset = true;

            let http = axios.create();
            http.post(this.apiUrl + '/reset').then(() => {
                this.errorReset = null;
                this.loadingReset = false;
                this.successReset = true;
                this.receiveInfos();

                this.routeTo("setup");

            }).catch(e => {
                this.checkGenericError(e);
                this.errorReset = e;
                this.loadingReset = false;
                this.successReset = false;

            });
        },

    }
}

</script>

<style lang="scss" scoped>

.file-name {
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.cloudConnector-title {
    font-size: 22px;
    font-weight: 500;
}

.system-title {
    font-weight: 500;
    font-size: $site-font-size-large;
}

.width-action {
    min-width: 20px
}

.support-icon {
    text-underline: none !important;
}

.width-input {
    min-width: 80px;
    white-space: nowrap;
}

.max-input {
    max-width: 120px !important;
}

.format-inputs {
    width: 70px !important;
}

.paper-info {
    font-size: 14px;
}

.document-name {
    font-size: 14px;
    margin-top: 5px;
    font-weight: 500;
}

.mirage {
    max-width: 30px;
    max-height: 30px;
}

.mirage-struct {
    width: 100%;
}

.dragover {
    border-color: $info-color;
}

.uk-link {
    text-decoration: none !important;
}

.uk-input, .uk-select {
    height: 50px !important;

    &:hover, &:focus {
        border-color: $info-color !important;
    }
}

.paper-name {
    white-space: nowrap;
    width: 120px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.uk-panel {
    max-height: 280px;
    border: initial !important;
    padding: initial !important;
}

.uk-panel-scrollable {
    height: initial !important;
    resize: none !important;
}

.section-text {
    font-weight: 500;
    margin-bottom: 10px;
}


.satellite-struct {
    margin: auto;
    max-width: 500px;
}

.description {
    font-size: 14px;
    margin-top: -2px;
    font-weight: 500;

    &.satellite {
        font-size: 15px;
    }
}

.divider {
    border-width: 3px;

    &.success {
        border-color: $success-color;
    }

    &.danger {
        border-color: $danger-color;
    }

    &.primary {
        border-color: $info-color;
    }
}

.status-text {
    font-weight: 500;
    font-size: 15px;

    &.active {
        font-size: 16px;
    }
}

.lastactivity {
    font-size: 14px;
}

.left {
    transform: rotate(-60deg);
    transform-origin: 80% 80%;
    position: absolute;
    margin-top: -50px;
    margin-left: -0px;
    z-index: 0;
    width: 65px;
}

.right {
    transform: rotate(60deg);
    transform-origin: 80% 80%;
    position: absolute;
    margin-top: -15px;
    margin-left: -40px;
    z-index: 0;
    width: 65px;
}

</style>
