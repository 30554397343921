<template>
    <div
        class="module-disabled-info"
        :uk-tooltip="
            disabled
                ? 'title:' + $t('x.module.disabled.hint', {x: $t(module)})
                : ''
        "
        :class="disabled ? 'module-disabled-struct' : ''">
        <span
            :class="
                disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'
            ">

            <div
                is-dark
                mode="default"
                class="uk-height-1-1">
                <Description v-if="description" :text="$t(description)" />




                <Translate
                    :parameter="{
                        used: settings.slideShowFiles.length,
                        total: settings.maxSlideShowFiles,
                    }"
                    translation-key="x.of.total.x.created" />
                <progress
                    :value="settings.slideShowFiles.length /settings.maxSlideShowFiles * 100 "
                    class="uk-progress"
                    max="100" />

                <div class="uk-margin-medium-top" />

                <div v-if="settings.slideShowFiles.length >= settings.maxSlideShowFiles">
                    <InfoBox mode="warning">
                        <Translate translation-key="limit.slideshows.message" :parameter="{x: settings.maxSlideShowFiles}" />
                    </InfoBox>
                </div>
                <Button v-else :action="newFile" has-action mode="primary">
                    {{ $t('x.add', {x: $t('file')}) }}
                </Button>

                <div class="uk-margin-medium-top" />


                <div v-if="!settings.slideShowFiles ||  settings.slideShowFiles.length === 0">
                    <Translate translation-key="none.x"
                               :parameter="{x:$t('files')}" />
                </div>

                <div
                    id="sortable-customers"
                    uk-sortable="handle: .uk-sortable-handle">
                    <div v-for="item in settings.slideShowFiles"

                         :id="item.id" :key="item.id">
                        <span v-on:click="selectFile(item)">
                        <Card
                            is-dark
                            body-class="uk-card-small"
                            mode="default"
                            :class="'cursor-pointer uk-margin uk-card-hover' + (item.id === hoveredID ? ' selected' : '')"
                        >
                            <div class="uk-grid" uk-grid>
                                <div v-if="false" class="uk-flex-middle uk-flex">
                                    <Icon
                                        class="uk-sortable-handle"
                                        :ratio="1.2"
                                        icon="table" />
                                </div>

                                <div class="uk-width-medium uk-flex-middle uk-flex-center uk-flex">
                                    <video
                                        v-if="isVideo(item.fileID)"
                                        :src="apiUrl+'/slide-show-thumbnails/'+item.id+'/'+item.fileID+'?token='+getToken"

                                        uk-video="autoplay: inview"
                                        muted
                                        class="customer-logo" />

                                    <img
                                        v-else
                                        :alt="item.fileID"
                                        :src="apiUrl+'/slide-show-thumbnails/'+item.id+'/'+item.fileID+'?token='+getToken"

                                        class="customer-logo" />

                                </div>


                                <div>
                                           <LabelWithError :label="$t('headline')" is-dark />

                                    <div v-if="item.headline.text" class="item-headline">
                                        {{ item.headline.text }}
                                    </div>
                                    <div v-else>
                                        <Translate translation-key="none" />
                                    </div>
<div class="uk-margin-top" />


                                    <div class="uk-grid-small" uk-grid>
                                        <div>
                                                   <LabelWithError :label="$t('text.color')" is-dark />
                                             <div class=" color-struct width cursor-pointer uk-text-center"
                                                  :uk-tooltip="'title:'+ item.headline.textColor"
                                                  :style="'background: '+item.headline.textColor"
                                             />
                                        </div>

                                        <div>

                                                   <LabelWithError :label="$t('background.color')" is-dark />
                                      <div class=" color-struct width cursor-pointer uk-text-center"
                                           :uk-tooltip="'title:'+ (item.headline.backgroundColor ? item.headline.backgroundColor : $t('none'))"
                                           :style="'background: '+item.headline.backgroundColor"
                                      />
                                        </div>
                                    </div>


                                </div>




                                <div>
                                           <LabelWithError :label="$t('message')" is-dark />

                                    <div v-if="item.message.text" class="item-headline">
                                        {{ item.message.text }}
                                    </div>
                                     <div v-else>
                                        <Translate translation-key="none" />
                                    </div>
<div class="uk-margin-top" />


                                    <div class="uk-grid-small" uk-grid>
                                        <div>
                                                   <LabelWithError :label="$t('text.color')" is-dark />
                                             <div class=" color-struct width cursor-pointer uk-text-center"
                                                  :uk-tooltip="'title:'+ item.message.textColor"
                                                  :style="'background: '+item.message.textColor"
                                             />
                                        </div>

                                        <div>

                                                   <LabelWithError :label="$t('background.color')" is-dark />
                                      <div class=" color-struct width cursor-pointer uk-text-center"
                                           :uk-tooltip="'title:'+ (item.message.backgroundColor ? item.message.backgroundColor : $t('none'))"
                                           :style="'background: '+item.message.backgroundColor"
                                      />
                                        </div>
                                    </div>


                                </div>




                                <div>
                                     <LabelWithError :label="$t('display.duration.in.seconds')" is-dark />

                                    <Icon icon="chevron-double-right" /><span class="uk-margin-small-left">
                                    {{ item.displayDurationInSeconds }}
                                </span>
                                </div>


                            </div>


                        </Card>
                            </span>

                    </div>
                </div>

                <div v-if="settings.slideShowFiles && settings.slideShowFiles.length>0" class="uk-margin-medium-top" />

                   <div v-if="settings.slideShowFiles.length >= settings.maxSlideShowFiles">
                    <InfoBox mode="warning">
                        <Translate translation-key="limit.slideshows.message" :parameter="{x: settings.maxSlideShowFiles}" />
                    </InfoBox>
                </div>
                <Button v-else :action="newFile" has-action mode="primary">
                    {{ $t('x.add', {x: $t('file')}) }}
                </Button>
            </div>

            <Modal :id="modalId" :title="$t('x.add', {x: $t('file')})" is-extended>


                <div class="uk-child-width-1-2@m uk-child-width-1-1" uk-grid>



   <div>

        <div class="uk-inline uk-width-1-1">
            <LabelWithError :error="fileError" :label="$t('file')" is-dark />

          <div v-if="file || selected" class="uk-position-relative favicon-struct uk-text-center" id="favicon-upload">


              <div v-if="isVideo(selected ? selected.fileID : file.name)">


                   <video v-if="file" class="favicon"
                          :src="getLocalFileUrl(file)"
                          muted
                          oncontextmenu="return false;" playsinline
                          uk-video="autoplay: inview" />


                        <video v-else-if="selected" class="favicon"
                               :src="apiUrl+'/slide-show-thumbnails/'+selected.id+'/'+selected.fileID+'?token='+getToken"
                               muted
                               oncontextmenu="return false;" playsinline
                               uk-video="autoplay: inview" />


</div>

              <div v-else>
                    <img v-if="file" class="favicon" :src="getLocalFileUrl(file)" uk-img />
                    <img v-else-if="selected" class="favicon"
                         :src="apiUrl+'/slide-show-thumbnails/'+selected.id+'/'+selected.fileID+'?token='+getToken"
                         uk-img />
              </div>







            <div v-if="file || selected" class="uk-margin-top  uk-text-center">

                <h2 class="uk-margin-remove uk-padding-small"
                    :style="'background: '+inputFields[0].backgroundColor.hex+ '; color: '+inputFields[0].textColor.hex"
                >
                    {{ inputFields[0].value }}
                </h2>

                <div class="uk-margin-small-top" />
                <p class="uk-padding-small"
                   :style="'background: '+inputFields[1].backgroundColor.hex+'; color: '+inputFields[1].textColor.hex"
                >
                    {{ inputFields[1].value }}
                </p>
            </div>
                </div>






            <div v-if="false && (file || selected)" class="uk-overlay uk-position-center uk-text-center">

                <h2 class="uk-margin-remove uk-padding-small"
                    :style="'background: '+inputFields[0].backgroundColor.hex+ '; color: '+inputFields[0].textColor.hex"
                >
                    {{ inputFields[0].value }}
                </h2>

                <div class="uk-margin-small-top" />
                <p class="uk-padding-small"
                   :style="'background: '+inputFields[1].backgroundColor.hex+'; color: '+inputFields[1].textColor.hex"
                >
                    {{ inputFields[1].value }}
                </p>
            </div>



        </div>

       <div class="uk-width-1-1">


<div id="file-upload"
     class="js-upload uk-placeholder uk-placeholder-hover uk-text-center cursor-pointer uk-width-1-1"
     v-on:click="triggerInputDialog()">
                            <div>
                                <Icon :ratio="2.2" class="upload-icon" icon="cloud-upload" />
                            </div>


                                <span class="uk-text-middle">{{ $t('x.drop.or.', {x: $t('file')}) }}</span>
                                <span class="uk-link uk-text-middle custom-link">{{ $t('select') }}</span>


                            <div class="uk-hidden" uk-form-custom>
                                <input id="file"
                                       ref="file"
                                       :accept="'.'+settings.allowedSlideShowFileExtensions.join(',')"
                                       type="file"
                                       v-on:change="handleFilesUpload()"
                                       v-on:click="$refs.file.value=null"
                                />
                            </div>

                            <div v-if="fileError" class="uk-margin-top" />
                            <LabelWithError v-if="fileError" :error="fileError" :label="$t('file')" />
                        </div>
            </div>


       <div v-if="file || selected" class="hint">
                  <Translate translation-key="preview.hint" />

       </div>

    </div>



                    <div class="">







                         <span v-for="(inputField, key) in inputFields" :key="inputField.name">
                    <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                               :autofocus="inputField.autofocus" :field-key="key"
                               :hasPasswordMeter="inputField.hasPasswordMeter"
                               :icon="inputField.icon"
                               :is-optional="inputField.optional"
                               :label="inputField.label"
                               :name="inputField.name"
                               :on-enter="createOrUpdate"
                               :rules="inputField.rules"
                               :type="inputField.type"
                               :validate="trigger"
                               @setValid="setValid"
                    >



                    </TextField>




<div v-if="key !== 2" class="uk-margin-small-top">

                            <div class="uk-child-width-1-2 uk-grid-small" uk-grid>
                                <div>
                                       <LabelWithError :label="$t('text.color')" is-dark />
                            <div class="color-struct cursor-pointer uk-text-center"
                                 :uk-tooltip="'title:'+ inputField.textColor.hex"
                                 :style="'background: '+inputField.textColor.hex"
                            />
                            <div uk-dropdown="mode: click" class="uk-padding-remove">
                                    <sketch-picker v-model="inputField.textColor" />
                                </div>
                                </div>


                                <div>



                                       <LabelWithError :label="$t('background.color')" is-dark />
                            <div class="color-struct cursor-pointer uk-text-center"
                                 :uk-tooltip="'title:'+ inputField.backgroundColor.hex"
                                 :style="'background: '+inputField.backgroundColor.hex"
                            />
                            <div uk-dropdown="mode: click" class="uk-padding-remove">

                                <span v-on:click="inputField.backgroundColor.hex = ''">
                                <Button is-max mode="default">
                                    <Translate translation-key="none" />
                                </Button>
                                    </span>
                                <div class="uk-margin-small-top" />


                                    <sketch-picker v-model="inputField.backgroundColor" />
                                </div>
                                </div>
                            </div>




                        </div>


                                                 <hr v-if="key < inputFields.length-1"
                                                     class="uk-hr uk-margin-top uk-margin-bottom" />

                    <div v-if="key < inputFields.length-1" class="uk-margin-bottom" />
                </span>

                    </div>
                </div>




<div class="uk-margin-medium-bottom" />

                <div class="uk-grid uk-grid-small" uk-grid>

                    <div v-if="selected" class="uk-width-auto">
                        <div :uk-tooltip="$t('x.delete', {x: $t('file')})">
                            <Button is-large

                                    mode="danger">
                                <Icon icon="trash" />
                            </Button>

                            <div uk-dropdown="mode: click">
                                <Translate translation-key="confirm.x.delete"
                                           :parameter="{x:$t('file')}" />
                                <div class="uk-margin-small-top" />

                                  <Button is-large
                                          has-action
                                          :action="actionDelete"
                                          mode="danger">
                          <Translate translation-key="delete" />
                            </Button>

                            </div>
                        </div>
                    </div>

                    <div class="uk-width-expand">
                        <Button
                            v-if="selected"
                            :action="createOrUpdate"
                            :is-loading="loading"
                            :text="$t(selected ? (selectedClone ? 'x.duplicate' : 'x.save') : 'x.add', {x: $t('file')})"
                            has-action is-large
                            is-max
                            mode="primary" />

                          <Button
                              v-else
                              :action="createOrUpdate"
                              :is-loading="loading"
                              :text="$t(selected ? (selectedClone ? 'x.duplicate' : 'x.save') : 'x.add', {x: $t('file')})"
                              has-action is-large
                              is-max
                              mode="primary" />
                    </div>
                </div>



           <div v-if="error" class="uk-margin-medium-bottom" />
                <ErrorMessage :error="error"
                              :custom-errors="[ {code: 403, message: $t('x.module.disabled.hint', {x: $t(module)})}]" />





            </Modal>

        </span>
    </div>
</template>

<script>
import Card from '@/components/generic/Card.vue';
import Description from '@/components/Description.vue';
import Button from '@/components/generic/Button.vue';
import Icon from '@/components/generic/Icon.vue';
import Modal from '@/components/generic/Modal.vue';
import UIkit from 'uikit';
import TextField from '@/components/generic/TextField.vue';
import {Sketch} from 'vue-color';
import ErrorMessage from '@/components/generic/ErrorMessage.vue';
import Translate from '@/components/Translate.vue';
import LabelWithError from '@/components/generic/LabelWithError.vue';
import axios from 'axios';
import InfoBox from '@/components/generic/InfoBox.vue';

export default {
    name: 'SmartSignageFilesForm',
    components: {
        InfoBox,
        LabelWithError,
        Translate,
        ErrorMessage,
        TextField,
        Modal,
        Icon,
        Button,
        Description,
        Card,
        'sketch-picker': Sketch,
    },
    props: {
        disabled: Boolean,
        settings: Object,
    },
    data() {
        return {
            title: 'files',
            description: null,
            module: 'smartSignage',

            loading: false,
            success: false,
            error: null,

            modalId: 'modal-file',

            hoveredID: null,

            trigger: 0,


            selected: {
                id: '',
                createdAt: '',
                fileID: '',
                headline: {
                    text: '',
                    backgroundColor: '',
                    textColor: '',
                },
                message: {
                    text: '',
                    backgroundColor: '',
                    textColor: '',
                },
                displayDurationInSeconds: '',
                sort: '',
            },
            selectedClone: false,

            file: null,
            fileError: null,

            inputFields: [
                {
                    value: '',
                    valid: true,

                    name: 'headline',
                    label: 'headline',
                    icon: 'hashtag',
                    rules: '',

                    textColor: {hex: '#ffffff'},
                    backgroundColor: {hex: ''},
                },
                {
                    value: '',
                    valid: true,

                    name: 'message',
                    label: 'message',
                    icon: 'commenting',
                    rules: '',

                    textColor: {hex: '#ffffff'},
                    backgroundColor: {hex: ''},
                },
                {
                    value: 6,
                    valid: true,

                    name: 'display-duration',
                    label: 'display.duration.in.seconds',
                    icon: 'chevron-double-right',
                    rules: 'required|integer|between:3,30',
                    type: 'number',
                },
            ],
        };
    },
    mounted() {
        window.addEventListener('dragover', this.dragoverListener, false);

        let element = document.getElementById('file-upload');
        if (element) {
            element.addEventListener('drop', this.dropListener, false);
        }
    },

    methods: {
        selectFile(file) {
            this.resetInputs();

            this.selected = file;

            this.inputFields[0].value = this.selected.headline.text;
            this.inputFields[0].textColor.hex = this.selected.headline.textColor;
            this.inputFields[0].backgroundColor.hex = this.selected.headline.backgroundColor;

            this.inputFields[1].value = this.selected.message.text;
            this.inputFields[1].textColor.hex = this.selected.message.textColor;
            this.inputFields[1].backgroundColor.hex = this.selected.message.backgroundColor;

            this.inputFields[2].value = this.selected.displayDurationInSeconds;

            UIkit.modal('#' + this.modalId).show();
        },
        resetInputs() {
            this.file = null;
            this.fileError = null;

            this.loading = false;
            this.success = false;
            this.error = null;


            this.selected = null;
            this.selectedClone = null;

            for (let i = 0, j = this.inputFields.length; i < j; i++) {
                this.inputFields[i].value = '';
                this.inputFields[i].valid = true;

                this.inputFields[i].textColor = {hex: '#ffffff'};
                this.inputFields[i].backgroundColor = {hex: ''};
            }

            this.inputFields[2].value = 6;

        },
        isVideo(fileName) {
            let fileExtension = fileName.split('.').pop();
            fileExtension = fileExtension.toLowerCase();

            if (['mp4', 'mp4v', 'webm'].includes(fileExtension)) {
                return true;
            }

            return false;
        },
        dropListener(e) {
            e.preventDefault();

            let element = document.getElementById('file-upload');
            element.classList.remove('dragover');

            let file = e.dataTransfer.files[0];
            this.processFile(file);
        },
        dragoverListener(e) {
            e.preventDefault();

            let element = document.getElementById('file-upload');
            element.classList.add('dragover');
        },
        getLocalFileUrl(file) {
            return URL.createObjectURL(file);
        },
        processFile(file) {
            this.fileError = null;

            let fileExtension = file.name.split('.').pop();
            fileExtension = fileExtension.toLowerCase();
            if (!this.settings.allowedSlideShowFileExtensions.includes('.' + fileExtension)) {
                this.fileError = this.$t('file.type.error', {
                    name: this.$t('file'),
                    types: this.settings.allowedSlideShowFileExtensions.join(', '),
                });
                return;
            }

            if (file.size > (this.settings.allowedSlideShowMaxFileSizeInBytes)) {
                this.fileError = this.$t('file.size.error', {
                    name: this.$t('file'),
                    mb: this.settings.allowedSlideShowMaxFileSizeInBytes / 1000 / 1000,
                });
                return;
            }

            this.file = file;
        },
        triggerInputDialog() {
            document.getElementById('file').click();
        },
        handleFilesUpload() {
            let file = this.$refs['file'].files[0];
            this.processFile(file);
        },
        notify(message) {
            UIkit.notification({
                message: message,
                status: 'success',
                pos: 'top-center',
                timeout: this.notificationTimeout,
            });
        },
        actionDelete() {
            if (!this.selected) {
                return;
            }

            let http = axios.create();
            http.delete(this.apiUrl + '/slide-show-files/' + this.selected.id).then(() => {
                this.error = null;
                this.success = false;
                this.loading = false;

                this.resetInputs();

                this.notify(this.$t('x.deleted', {x: this.$t('file')}));
                UIkit.modal('#' + this.modalId).hide();


                this.$emit('updated');

            }).catch(e => {
                this.checkGenericError(e);
                this.error = e;
                this.success = false;
                this.loading = false;

            });
        },
        createOrUpdate() {
            this.trigger++;

            for (let i = 0, j = this.inputFields.length; i < j; i++) {
                if (!this.inputFields[i].valid) {
                    return;
                }
            }

            let formData = new FormData();

            if (this.file) {
                formData.append('file', this.file);
            }

            formData.append('headlineText', this.inputFields[0].value);
            formData.append('headlineBackgroundColor', this.inputFields[0].backgroundColor.hex);
            formData.append('headlineTextColor', this.inputFields[0].textColor.hex);

            formData.append('messageText', this.inputFields[1].value);
            formData.append('messageBackgroundColor', this.inputFields[1].backgroundColor.hex);
            formData.append('messageTextColor', this.inputFields[1].textColor.hex);

            formData.append('displayDurationInSeconds', this.inputFields[2].value);

            if (this.selected) {
                this.loading = true;
                this.actionUpdate(formData);
                return;
            }

            if (!this.file) {
                this.fileError = this.$t('x.required.message', {x: this.$t('file')});
                return;
            }

            this.loading = true;
            this.actionCreate(formData);
        },
        setValid(valid, fieldKey) {
            this.inputFields[fieldKey].valid = valid;
        },
        newFile() {
            this.resetInputs();

            UIkit.modal('#' + this.modalId).show();
        },
        actionUpdate(formData) {
            let http = axios.create();
            http.patch(this.apiUrl + '/slide-show-files/' + this.selected.id, formData).then(() => {
                this.hoveredID = this.selected.id;

                this.error = null;
                this.success = false;
                this.loading = false;

                this.resetInputs();

                this.notify(this.$t('x.changes.saved', {x: this.$t('file')}));
                UIkit.modal('#' + this.modalId).hide();

                this.$emit('updated');

            }).catch(e => {
                this.checkGenericError(e);
                this.error = e;
                this.success = false;
                this.loading = false;

            });
        },
        actionCreate(formData) {
            let http = axios.create();
            http.post(this.apiUrl + '/slide-show-files', formData).then(response => {
                this.hoveredID = response.data.id;

                this.resetInputs();

                this.notify(this.$t('x.created', {x: this.$t('file')}));
                UIkit.modal('#' + this.modalId).hide();

                this.$emit('updated');

            }).catch(e => {
                this.checkGenericError(e);
                this.error = e;
                this.success = false;
                this.loading = false;

            });
        },
    },
}
;
</script>

<style lang="scss" scoped>
.customer-logo {
    max-width: 200px !important;
    max-height: 120px !important;
}

.hint {
    margin-top: 10px;
}

.color-struct {
    line-height: 50px;
    transition: border-color 0.25s linear, color 0.25s linear, background-color 0.25s linear !important;

    border-width: 1px;
    border-style: solid;
    border-radius: $border-radius;
    border-color: $border-color !important;
    height: 50px;
    color: $site-background-hover !important;
    width: 100%;
    font-weight: 500;
    font-size: 16px;

    &:hover, &:focus {
        border-color: $info-color !important;
    }

    &.width {
        width: 50px;
    }
}

.item-headline {
    font-weight: 500;
    font-size: 16px;
}

.favicon {
    max-width: 512px;
    max-height: 256px;

}

.favicon-struct {
    padding: 15px;
    background-color: rgba(34, 34, 34, 0.72);
    border: 1px rgba(34, 34, 34, 0.72) solid;
    backdrop-filter: saturate(180%) blur(20px);
    transition: background-color .25s linear;
}

.dragover {
    background-color: rgba(34, 34, 34, 0.4);
    border: 1px rgba(34, 34, 34, 0.72) dashed;
}


</style>
